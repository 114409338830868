import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/NavBar';
import Routes from './Routes';
import { MDBMask, MDBView } from 'mdbreact';
import './styles/styles.css';

function App() {

  return (
    <Router>
      <div id='apppage'>
        <MDBView>
          <MDBMask className='white-text gradient' />
          <NavBar />
          <main style={{ marginTop: '10vh', minHeight: '90vh' }}>
            <Routes/>
          </main>
        </MDBView>
      </div>
    </Router>
  )
}

export default App;
