import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Landing from './pages/Landing';
import Result from './pages/Result';
import Demo from './pages/Demo';

class Routes extends React.Component {

  render() {

    return (
      <Switch>
        <Route exact path='/' component={(props) => <Landing {...props} />} />
        <Route exact path="/result" component={(props) => <Result {...props} /> } />
        <Route exact path="/demo" component={(props) => <Demo {...props} /> } />
        {/* Redirect to landing page if page does not exist */}
        <Redirect to='/' />
      </Switch>
    );
  }
}

export default Routes;
