import { MDBRow, MDBCol } from 'mdbreact';
import React from 'react';
import WaveSurfer from 'wavesurfer.js';
import YouTube from 'react-youtube';

class Song extends React.Component {

  state = {
    player: null
  }

  componentDidMount = () => {
    this.createLyricsSubtitles()
    const accTrack = document.getElementById('accTrack' + this.props.num)
    this['accWave' + this.props.num] = WaveSurfer.create({
      interact: true,
      container: '#accWave' + this.props.num,
      progressColor: '#A9C388',
      responsive: true,
      waveColor: '#404040',
      cursorColor: 'transparent',
    });
    this['accWave' + this.props.num].load(accTrack);
    this['accWave' + this.props.num].on('seek', (progress) => {
      this.onSeek(progress);
    })

    const gyroTrack = document.getElementById('gyroTrack' + this.props.num)
    this['gyroWave' + this.props.num] = WaveSurfer.create({
      interact: true,
      container: '#gyroWave' + this.props.num,
      progressColor: '#A9C388',
      responsive: true,
      waveColor: '#404040',
      cursorColor: 'transparent',
    });
    this['gyroWave' + this.props.num].load(gyroTrack);
    this['gyroWave' + this.props.num].on('seek', (progress) => {
      this.onSeek(progress);
    })


    const audioTrack = document.getElementById('track' + this.props.num)
    this['waveform' + this.props.num] = WaveSurfer.create({
      interact: true,
      container: '#waveform' + this.props.num,
      progressColor: '#A9C388',
      responsive: true,
      waveColor: '#404040',
      cursorColor: 'transparent',
    });

    this['waveform' + this.props.num].load(audioTrack);
    this['waveform' + this.props.num].on('seek', (progress) => {
      this.onSeek(progress);
    })

    this['waveform' + this.props.num].on('audioprocess', (progress) => {
      let subtitles = document.getElementById("lyrics" + this.props.num);
      this.props.syncData.forEach(function (element, index, array) {
        if (progress >= element.start)
          subtitles.children[index].style.color = '#A9C388';
      });

    })
  }

  createLyricsSubtitles = () => {
    let subtitles = document.getElementById("lyrics" + this.props.num);
    let syncData = this.props.syncData;

    for (var i = 0; i < syncData.length; i++) {
      let element = document.createElement('span');
      element.setAttribute("id", "c_" + i);
      element.innerText = syncData[i].text + " ";
      subtitles.appendChild(element);
    }
  }

  onSeek = (progress) => {
    if (this.state.player) {
      if (progress * this['accWave' + this.props.num].getDuration() !== this['accWave' + this.props.num].getCurrentTime()) {
        this['accWave' + this.props.num].seekTo(progress);
      }

      if (progress * this['gyroWave' + this.props.num].getDuration() !== this['gyroWave' + this.props.num].getCurrentTime()) {
        this['gyroWave' + this.props.num].seekTo(progress);
      }

      if (progress * this['waveform' + this.props.num].getDuration() !== this['waveform' + this.props.num].getCurrentTime()) {
        this['waveform' + this.props.num].seekTo(progress);
      }
      let player = this.state.player;
      let time = player.getDuration() * progress;
      player.seekTo(time);
      let subtitles = document.getElementById("lyrics" + this.props.num);
      this.props.syncData.forEach(function (element, index, array) {
        if (time >= element.start) {
          subtitles.children[index].style.color = '#A9C388';
        } else {
          subtitles.children[index].style.color = '#404040';
        }
      });
    } else {
      window.setTimeout(() => {
        this['waveform' + this.props.num].seekTo(0);
        this['accWave' + this.props.num].seekTo(0);
        this['gyroWave' + this.props.num].seekTo(0);
      }, 500);
    }
  }

  ready = (event) => {
    this.setState({ player: event.target })
  }

  onEnd = () => {
    let subtitles = document.getElementById("lyrics" + this.props.num);
    this.props.syncData.forEach(function (element, index, array) {
      subtitles.children[index].style.color = '#404040';
    });
    let player = this.state.player;
    this['waveform' + this.props.num].seekTo(0);
    player.seekTo(0);
    player.pauseVideo();
  }

  handlePlay = () => {
    if (this.state.player) {
      let player = this.state.player;
      let state = player.getPlayerState();
      if (state === 1) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    }
  };


  playWaveForm = () => {
    this['waveform' + this.props.num].play()
    this['accWave' + this.props.num].play()
    this['gyroWave' + this.props.num].play()
  }


  pauseWaveForm = () => {
    this['waveform' + this.props.num].pause();
    this['accWave' + this.props.num].pause()
    this['gyroWave' + this.props.num].pause()
  }


  render() {

    const opts = {
      height: '110%',
      width: '110%',
      playerVars: {
        controls: 0,
        autoplay: 0,
      },
    };

    return (
      <div>

        <div id="line"></div>

        <MDBRow style={{ paddingTop: '1vh' }}>

          <MDBCol md="12">
            <div style={{ padding: '10px 0px' }} className="sectionHeader">
              {this.props.title}
            </div>
          </MDBCol>

          <MDBCol md="8">
            <MDBRow style={{ marginBottom: '3vh', paddingTop: '1vh' }}>
              <MDBCol md="6">

                <div className="textHeader font-weight-bold text-center">
                  IMU Acc
                </div>
                <div id={"accWave" + this.props.num}>
                  <audio style={{ display: 'none' }} id={"accTrack" + this.props.num} src={this.props.acc} controls type="audio/wav"></audio>
                </div>

              </MDBCol>

              <MDBCol md="6">
                <div className="textHeader font-weight-bold text-center">
                  {"Video File"}
                </div>

                <div id="player" style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                  <YouTube videoId={this.props.ytLink} opts={opts} onEnd={this.onEnd} onPlay={this.playWaveForm} onPause={this.pauseWaveForm} onReady={this.ready} />
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow style={{ marginBottom: '3vh', paddingTop: '1vh' }}>
              <MDBCol md="6">

                <div className="textHeader font-weight-bold text-center">
                  IMU Gyro
                </div>
                <div id={"gyroWave" + this.props.num}>
                  <audio style={{ display: 'none' }} id={"gyroTrack" + this.props.num} src={this.props.gyro} controls type="audio/wav"></audio>
                </div>
              </MDBCol>

              <MDBCol md="6">
                <div className="textHeader font-weight-bold text-center">
                  {"Audio Waveform"}
                </div>

                <div id={"waveform" + this.props.num}>
                  <audio style={{ display: 'none' }} id={"track" + this.props.num} src={this.props.audio} controls type="audio/wav"></audio>
                </div>

              </MDBCol>
            </MDBRow>

          </MDBCol>

          <MDBCol style={{ margin: 'auto', paddingLeft: '50px' }} md="4">
            <div style={{ color: 'black' }} className="textHeader font-weight-bold text-center">
              Lyric Transcription Results:
            </div>
            <div id={"lyrics" + this.props.num} className="lyricTranscription"></div>
            <div style={{ marginTop: '3vh', display: 'flex', justifyContent: 'center' }}>
              <button className="uploadBtn textDescription" onClick={this.handlePlay}>
                Play/Pause
              </button>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default Song;
