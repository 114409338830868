import { MDBRow, MDBCol } from 'mdbreact';
import React from 'react';
import Song from '../components/Song.js'
import audio1 from '../assets/audio/audio1.wav';
import acc1 from '../assets/audio/acc1.wav';
import gyro1 from '../assets/audio/gyro1.wav';
import audio2 from '../assets/audio/audio2.wav';
import acc2 from '../assets/audio/acc2.wav';
import gyro2 from '../assets/audio/gyro2.wav';
import audio3 from '../assets/audio/audio3.wav';
import acc3 from '../assets/audio/acc3.wav';
import gyro3 from '../assets/audio/gyro3.wav';

const syncData1 = [
  { "end": "0.29", "start": "0.05", "text": "The" },
  { "end": "0.7", "start": "0.3", "text": "other" },
  { "end": "1.1", "start": "0.71", "text": "night" },
  { "end": "1.8", "start": "1.11", "text": "dear," },
  { "end": "2.4", "start": "2.05", "text": "as" },
  { "end": "2.6", "start": "2.41", "text": "I" },
  { "end": "2.76", "start": "2.61", "text": "lay" },
  { "end": "3.8", "start": "2.77", "text": "sleeping" },
];

const syncData2 = [
  { "end": "1.0", "start": "0.05", "text": "Small" },
  { "end": "1.5", "start": "1.11", "text": "and" },
  { "end": "2", "start": "1.51", "text": "white," },
  { "end": "4.2", "start": "3.2", "text": "clean" },
  { "end": "4.75", "start": "4.22", "text": "and" },
  { "end": "5.85", "start": "4.76", "text": "bright" },
];

const syncData3 = [
  { "end": "0.29", "start": "0.01", "text": "I" },
  { "end": "0.8", "start": "0.3", "text": "wonder" },
  { "end": "1.34", "start": "0.81", "text": "how," },
  { "end": "1.93", "start": "1.7", "text": "I" },
  { "end": "2.45", "start": "1.94", "text": "wonder" },
  { "end": "2.94", "start": "2.46", "text": "why" },
];

class Demo extends React.Component {

  state = {
  }

  render() {

    const styles = {
      landing: {
        padding: '10vh 15vw 5vh 15vw'
      },
    };

    return (
      <div style={styles.landing}>
        <MDBRow>

          <MDBCol md="12">
            <div className="header">
              {"Demo"}
            </div>
          </MDBCol>

        </MDBRow>
        <Song ytLink="8FvGNqC5nA8" syncData={syncData1} title="You Are My Sunshine" num={1} audio={audio1} acc={acc1} gyro={gyro1}></Song>
        <Song ytLink="QzKBXRhQm74" syncData={syncData2} title="Edelweiss" num={2} audio={audio2} acc={acc2} gyro={gyro2}></Song>
        <Song ytLink="BVWBQ3ViVpM" syncData={syncData3} title="Lemon Tree" num={3} audio={audio3} acc={acc3} gyro={gyro3}></Song>
      </div>
    );
  }
}

export default Demo;
