import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../styles/styles.css';


class NavBar extends Component {

  state = {
    selectedTab: '',
  }

  componentDidMount = () => {
    let url = window.location.href;
    let index = url.lastIndexOf("/");
    let tab = url.slice(index + 1);
    if (tab === "") {
      tab = "home";
    }
    this.changeTabColor(tab);
  }

  changeTabColor = (tab) => {
    window.scrollTo(0, 0);
    if (this.state.selectedTab !== "") {
      document.getElementById(this.state.selectedTab).style.backgroundColor = '';
    }
    document.getElementById(tab).style.backgroundColor = '#0D0D0D';
    this.setState({ selectedTab: tab });
  }

  openNewTab = (link) => {
    window.open(link);
  }



  render() {
    const styles = {
      navBar: {
        zIndex: '100',
        height: '10vh',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        backdropFilter: 'blur(1rem)',
        background: '#313132',
        opacity: '0.85',
      },
      navBrand: {
        color: '#A2B689',
        fontSize: '36px',
        fontWeight: 'bold'
      },
      navLink: {
        color: '#A2B689',
      }
    };

    return (
      <>
        <MDBNavbar dark expand='xs' fixed='top' scrolling style={styles.navBar}>
          <MDBNavbarNav className="nav" left>
            <MDBNavbarBrand onClick={() => this.changeTabColor("home")} href='/' className="navBrand" style={styles.navBrand}>
              {"MM-ALT"}
            </MDBNavbarBrand>

          </MDBNavbarNav>

          <MDBNavbarNav right>

            <MDBNavItem className="ml-3">
              <MDBNavLink id="home" onClick={() => this.changeTabColor("home")} className="navLink" style={styles.navLink} to="/">
                {"Home"}
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem className="ml-3">
              <MDBNavLink id="result" onClick={() => this.changeTabColor("result")} className="navLink" style={styles.navLink} to="/result">
                {"Results"}
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem className="ml-3">
              <MDBNavLink id="demo" onClick={() => this.changeTabColor("demo")} className="navLink" style={styles.navLink} to="/demo">
                {"Demo"}
              </MDBNavLink>
            </MDBNavItem>

            
            <MDBNavItem className="ml-3">
              <MDBNavLink id="dataset" onClick={() => window.open("https://zenodo.org/record/7545968", "_blank")} className="navLink" style={styles.navLink} to="#">
                {"Dataset"}
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem className="ml-3">
              <MDBNavLink className="navLink" onClick={() => window.open("https://arxiv.org/abs/2207.06127", "_blank")} style={styles.navLink} to="#">
                {"Paper"}
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem className="ml-3">
              <MDBNavLink className="navLink" onClick={() => window.open("https://github.com/guxm2021/MM_ALT","_blank")} style={styles.navLink} to="#">
                {"Code"}
              </MDBNavLink>
            </MDBNavItem>

          </MDBNavbarNav>

        </MDBNavbar >
      </>
    )
  };
}

export default withRouter(NavBar);
